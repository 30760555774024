import { render, staticRenderFns } from "./WalletManagement.vue?vue&type=template&id=376fcf80&scoped=true"
import script from "./WalletManagement.vue?vue&type=script&lang=js"
export * from "./WalletManagement.vue?vue&type=script&lang=js"
import style0 from "./WalletManagement.vue?vue&type=style&index=0&id=376fcf80&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376fcf80",
  null
  
)

export default component.exports