<template>
    <div class="wallet-management">
      <a-card title="用户钱包管理" :bordered="false">
        <!-- 搜索区域 -->
        <a-form layout="inline" class="search-form">
          <a-form-item label="用户ID">
            <a-input v-model="searchForm.uid" placeholder="请输入用户ID" />
          </a-form-item>
          <a-form-item label="用户昵称">
            <a-input v-model="searchForm.username" placeholder="请输入用户昵称" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 8px" @click="handleReset">重置</a-button>
          </a-form-item>
        </a-form>
  
        <!-- 表格区域 -->
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          :pagination="pagination"
          :row-key="record => record.id"
          @change="handleTableChange"
        >
          <template slot="vipFlag" slot-scope="text">
            <a-tag v-if="text" color="gold">是</a-tag>
            <a-tag v-else>否</a-tag>
          </template>
          <template slot="avatar" slot-scope="text">
            <a-avatar :src="text" />
          </template>
          <template slot="balance" slot-scope="text">
            ¥{{ text.toFixed(2) }}
          </template>
        </a-table>
      </a-card>
    </div>
  </template>
  
  <script>
  const columns = [
    {
      title: '用户ID',
      dataIndex: 'user.uid',
      key: 'user.uid'
    },
    {
      title: '头像',
      dataIndex: 'user.avatar',
      key: 'user.avatar',
      scopedSlots: { customRender: 'avatar' }
    },
    {
      title: '用户名',
      dataIndex: 'user.username',
      key: 'user.username'
    },
    {
      title: '是否为VIP',
      dataIndex: 'user.vipFlag',
      key: 'user.vipFlag',
      scopedSlots: { customRender: 'vipFlag' }
    },
    {
      title: '可用余额',
      dataIndex: 'balance',
      key: 'balance',
      scopedSlots: { customRender: 'balance' },
      sorter: true
    },
    {
      title: '待入账',
      dataIndex: 'pendingIncome',
      key: 'pendingIncome',
      scopedSlots: { customRender: 'balance' },
      sorter: true
    },
    {
      title: '待出账',
      dataIndex: 'pendingExpense',
      key: 'pendingExpense',
      scopedSlots: { customRender: 'balance' },
      sorter: true
    },
    {
      title: '总收入',
      dataIndex: 'totalIncome',
      key: 'totalIncome',
      scopedSlots: { customRender: 'balance' },
      sorter: true
    },
    {
      title: '已提现',
      dataIndex: 'totalWithdraw',
      key: 'totalWithdraw',
      scopedSlots: { customRender: 'balance' },
      sorter: true
    },//提现中
    {
      title: '提现中',
      dataIndex: 'withdrawing',
      key: 'withdrawing',
      scopedSlots: { customRender: 'balance' },
      sorter: true
    }
  ];
  
  export default {
    name: 'WalletManagement',
    data() {
      return {
        columns,
        dataSource: [],
        loading: false,
        searchForm: {
          uid: '',
          username: ''
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        detailVisible: false,
        currentWallet: {}
      };
    },
    methods: {
      handleSearch() {
        this.pagination.current = 1;
        this.fetchWalletList();
      },
      handleReset() {
        this.searchForm = {
          uid: '',
          username: ''
        };
        this.handleSearch();
      },
      handleTableChange(pagination, filters, sorter) {
        this.pagination = pagination;
        const params = {
          current: pagination.current,
          pageSize: pagination.pageSize,
          userId: this.searchForm.userId
        };
        
        if (sorter.field) {
          params.sortField = sorter.field.replace(/([A-Z])/g, '_$1').toUpperCase();
          params.sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        }
        
        this.fetchWalletList(params);
      },
      showWalletDetail(record) {
        this.currentWallet = record;
        this.detailVisible = true;
      },
      handleDetailCancel() {
        this.detailVisible = false;
      },
      fetchWalletList(params) {
        this.loading = true;
        let that=this;
        that.$get("Stream/wallets",{
              ...params,
              uid: this.searchForm.uid,
              username: this.searchForm.username,
              current: this.pagination.current,
              pageSize: this.pagination.pageSize
            }).then((res)=>{
              console.log(res);
                that.dataSource = res.data.records;
                that.pagination.total = res.data.total;
                that.loading = false;
            });
        // const res = await request({
        //   url: '/wallets',
        //   method: 'GET',
        //   params: {
        //       ...params,
        //       uid: this.searchForm.uid,
        //       username: this.searchForm.username,
        //       current: this.pagination.current,
        //       pageSize: this.pagination.pageSize
        //     }
        //   });
        //   this.dataSource = res.records;
        //   this.pagination.total = res.total;
        //   this.loading = false;
      }
    },
    created() {
      this.fetchWalletList();
    }
  };
  </script>
  
  <style scoped>
  .wallet-management {
    padding: 24px;
  }
  .search-form {
    margin-bottom: 24px;
  }
  </style>
  